import React from 'react';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import Helmet from "react-helmet"
import EventsTimeline from 'react-events-timeline';
import 'react-events-timeline/dist/main.css';


const interndata = [
  {
    date: "2022",
    title: 'mercari@build',
    label: '',
    location: '2022/5/20',
    content: (<div></div>),
},
];




const awarddata = [
    
  {
    date: "2021",
    title: 'JPHACKS2021　Award Day Finalist',
    label: '',
    location: '2021/11/20',
    content: (<div>Product:ココノマスク</div>),
},

  {
    date: "2021",
    title: 'JPHACKS2021　Best Hacking Sprint Award ',
    label: '',
    location: '2021/10/30',
    content: (<div>Product:ココノマスク</div>),
},    
  {
    date: "2021",
    title: 'テクのこ　テクのこAward',
    label: '',
    location: '2021/9/28',
    content: (<div>Product:MAPLE</div>),
},
{
  date: "2021",
  title: '技育展　出場',
  label: '',
  location: '2021/8/28',
  content: (<div>Product:BOOKTREE</div>),
},
];


const Experience = () => (
  <Layout className="outerPadding">
    <Layout className="container">

      <Header />
      <Helmet title="almikan | Experience" />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Experience</h1>
        </div>

        <EventsTimeline title='Internships & Jobs' icon={ <i className='fa fa-code'/>} color='' data={interndata} />
        <br/><br/><br/>
        <EventsTimeline title='Awards' icon={ <i  className='fa fa-trophy '/>} color='#FFDC00' data={awarddata} />


  </SidebarWrapper>
    </Layout>
    <div className='bottomtxt'>
    <a className="bottomlink" href='https://privacypolicy.almikan.com/'>privacy policy</a>・Powered by <a className="bottomlink" href='https://github.com/rolwin100/rolwinreevan_gatsby_blog'>rolwinreevan gatsby blog theme</a> for Gatsby.
    </div>
  </Layout>
);

export default Experience;
